import { default as loginNeyVLlS2r8Meta } from "/codebuild/output/src229767524/src/observer-ui/pages/auth/login.vue?macro=true";
import { default as signupv4scyDD3qkMeta } from "/codebuild/output/src229767524/src/observer-ui/pages/auth/signup.vue?macro=true";
import { default as index5bOYuzuseyMeta } from "/codebuild/output/src229767524/src/observer-ui/pages/index.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginNeyVLlS2r8Meta || {},
    component: () => import("/codebuild/output/src229767524/src/observer-ui/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupv4scyDD3qkMeta || {},
    component: () => import("/codebuild/output/src229767524/src/observer-ui/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src229767524/src/observer-ui/pages/index.vue").then(m => m.default || m)
  }
]